'use strict'

/**
 * Object used to validate the new user.
 *
 * @author Andres Felipe Correa
 * @since 10/07/2020 10:04 AM
 * @version 1.0.0
 */
export default {
  firstPassword: {
    required: true,
    max: 6
  },
  secondPassword: {
    required: true,
    max: 6
  }
}
