<template>
  <v-container>
    <v-row
      no-gutters
    >
      <v-card-subtitle class="pt-5 col col-12 headline text-uppercase grey--text">
        {{ $t('register_codel_label') }}
      </v-card-subtitle>
      <v-col
        cols="12"
        class="pt-1"
      >
        <v-divider class="primary pa-0 mb-4" />
      </v-col>
      <v-col
        cols="12"
        class="pt-1"
      >
        <v-card-subtitle class="subtitle-1 pt-0 col col-12 grey--text">
          {{ $t('scan_qr') }}
        </v-card-subtitle>
      </v-col>
    </v-row>
    <ValidationObserver
      ref="obs"
      v-slot="{ passes }"
    >
      <v-form
        @submit.prevent="passes(saveTwoFactor)"
      >
        <v-row
          justify="center"
          no-gutters
        >
          <v-col cols="3">
            <v-img
              id="output"
              position="top"
              :src="qrCode"
              width="100%"
            />
          </v-col>
        </v-row>
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="2"
            class="py-0"
          >
            <app-text-field-with-validation
              id="firstPassword"
              v-model="firstPassword"
              :rules="registerCodeValidations.firstPassword"
              :label="$t('first_code').toLowerCase()"
              :label-input="$t('first_code')"
              name="firstPassword"
              type="number"
            />
          </v-col>
          <v-col
            cols="2"
            class="py-0"
          >
            <app-text-field-with-validation
              id="secondPassword"
              v-model="secondPassword"
              :rules="registerCodeValidations.secondPassword"
              :label="$t('second_code').toLowerCase()"
              :label-input="$t('second_code')"
              name="secondPassword"
              type="number"
            />
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <v-col cols="10">
            <v-card-subtitle class="subtitle-1 pt-0 col col-12 grey--text">
              {{ $t('download_apps') }}
            </v-card-subtitle>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          justify="center"
          align="start"
        >
          <v-col cols="3">
            <figure class="d-flex align-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                target="_blank"
              >
                <v-img
                  id="output"
                  position="top"
                  src="../../../../../../../../public/img/google-play.png"
                  width="80%"
                />
              </a>
            </figure>
          </v-col>
          <v-col cols="3">
            <figure class="d-flex align-center">
              <a
                href="https://apps.apple.com/es/app/google-authenticator/id388497605"
                target="_blank"
              >
                <v-img
                  id="output"
                  position="top"
                  src="../../../../../../../../public/img/app-store.png"
                  width="80%"
                />
              </a>
            </figure>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end">
          <v-btn
            color="gray_color lighten-1 white--text"
            large
            class="mt-12 mb-4 mr-4"
            @click="toReturn"
          >
            {{ $t('exit') }}
          </v-btn>
          <v-btn
            color="primary"
            large
            class="mt-12 mb-4 mr-10"
            type="submit"
          >
            {{ $t('save_register_user') }}
          </v-btn>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import registerCodeValidations from './data/registerCodeValidations'
import { GENERATOR_QR, SEND_CODES } from './data/registerCodeConstants'
import { ADD_COUNTER_REQUEST, SUBTRACT_COUNTER_REQUEST, SHOW_ALERT } from '@/store/mutations-types'
import { CONFLICT, BAD_REQUEST } from '@/shared/constants/httpStatus'
import handleErrorMixin from '@/mixins/handleErrorMixin'

export default {
  name: 'RegisterKeyTwoFactor',
  mixins: [handleErrorMixin],
  beforeRouteEnter (to, from, next) {
    if (to.params.userCreated && to.params.userCreated.id) {
      next()
    } else {
      next({ name: 'RegisterNewUser' })
    }
  },
  data () {
    return {
      registerCodeValidations,
      qrCode: '',
      // qrCode: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADIAQAAAACFI5MzAAACf0lEQVR4Xu2Wsa2kMBCGBzlwBg1YchtktAQNwNIAtETmNiy5AcgILOb+2ffu1vt0F6x9IRZCaD4Jw/wzv4f4X4t+Bv6sm9xE1k2+CZE2RPbYYq/VsVFVRCw7MwK2lk+7Oj6KiKmcqTXzhrtaHQ2lRD3asLf2OMP8HwivJ++Eb/dVKUEOaCRfsVpPxT+y8zERfQb3+/qp3KcEy4+dPZ5CNd+RbKJ2HR7ke1Ir2/X0r31yCPNpDxfYRSLow2sRsQvZmQ11cdR2dqqMxLpjNEez+cnZnazE8om6dGwc1WTqTi0iewkJK5u65b316JIHmTISp00tOhwMgrzaJAcZxFSnWiUHqGsa9ZsffE4YXzqwH1xsNjOhiYuIOk6R5fiqnY2mIuJHMj3cpYvVhhpPKjGHKPz01SGv1Di1d1Zi+YQICm9yb5h6yUcJiXApPiELrEXNnLhLDoGPoue8eDOF4712PieMd0vbsb06bGKfsWyi4O699s8cYKvED3JIwOsbB8cypMPV+qmIiO09WjN2kMgubep8GQT6hOPEcfFlqPzqkhwSLm16kqSuTJNLuiSH2KuVrt11JHiV9slJm0FgVLhiT/bS6LyQKJdBIiad2YHEiSF7mtEMwhdGiZP6FruhGFPnyyAIi6ng0EYO5i31nQyCowx/HwdHaLu32skhOL7C0gqsOxhqUjs5xMKJMemgqutnEU1FBAth8SrxeDRNEcEsJprMGAHwkM4UOeQ5X7dwekwBGDlVkoMMIj4KuyJSSyde9frqTMKrg2NB8LDoZKbIJBHj/yQjQID/vTo4h8h8DVfutV03M5wSKiAkzSHQNEhG93Zqfk7+um5yE1k3ySO/AAmQtKfFXu6TAAAAAElFTkSuQmCC',
      model: {
        username: this.$route.params.userCreated.username,
        verificationCodeList: []
      },
      firstPassword: '',
      secondPassword: ''
    }
  },
  created () {
    this.generatorCodeMDF()
  },
  methods: {
    /**
     * This method send codes to validation and complete the registration
     * Este método envía los códigos para validarlos and completa el autoregistro
     *
     * @author Andres Correa
     * @since 03/08/2020 9:10 PM
     * @version 1.0.0
     */
    saveTwoFactor () {
      this.$store.commit(ADD_COUNTER_REQUEST)
      this.model.verificationCodeList.push(this.firstPassword)
      this.model.verificationCodeList.push(this.secondPassword)
      this.axios.post(SEND_CODES, this.model).then((response) => {
        this.$store.commit(SHOW_ALERT, {
          type: 'success',
          text: this.$t('save_success'),
          show: true
        })
        this.$router.push({ name: 'Authentication' })
      }).catch((error) => {
        if (error.response.status === BAD_REQUEST) {
          this.$store.commit(SHOW_ALERT, {
            type: 'warning',
            text: this.$t('incorrect_code'),
            show: true
          })
          return
        }
        this.$_handleErrorMixin_generateError(error)
      }).finally(() => {
        this.$store.commit(SUBTRACT_COUNTER_REQUEST)
        this.model.verificationCodeList = []
      })
    },
    /**
     * This method get the QR code for the complet register.
     * Este método obtiene la imagen del código QR
     *
     * @author Andres Correa
     * @since 03/08/2020 9:10 PM
     * @version 1.0.0
     */
    generatorCodeMDF () {
      this.axios.get(`${GENERATOR_QR}/${this.$route.params.userCreated.id}`, {
        responseType: 'blob'
      }).then((response) => {
        this.qrCode = URL.createObjectURL(response.data)
      }).catch((error) => {
        if (error.response.status === CONFLICT) {
          this.$store.commit(SHOW_ALERT, {
            type: 'warning',
            text: this.$t('wrong_username'),
            show: true
          })
          return
        }
        this.$_handleErrorMixin_generateError(error)
      })
    },
    /**
     * This method return to the view of Autentication
     * Este método retorna a la vista de autenticación
     *
     * @author Andres Correa
     * @since 03/08/2020 9:10 PM
     * @version 1.0.0
     */
    toReturn () {
      this.$router.push({ name: 'Authentication' })
    }
  }
}
</script>
<style>
.v-btn.primary > span {
  color: #424242;
}
</style>
<i18n src="./data/i18nMessage.json" />
