import { render, staticRenderFns } from "./RegisterCode.vue?vue&type=template&id=626c059e&"
import script from "./RegisterCode.vue?vue&type=script&lang=js&"
export * from "./RegisterCode.vue?vue&type=script&lang=js&"
import style0 from "./RegisterCode.vue?vue&type=style&index=0&id=626c059e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./data/i18nMessage.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fview%2Ffrontoffice%2Fpublic%2FregisterNewUser%2Fcomponents%2FregisterCode%2FRegisterCode.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports